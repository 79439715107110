import { firebase, firebaseui, FirebaseUIModule } from "firebaseui-angular"

export const firebaseUiAuthConfig: firebaseui.auth.Config = {
	signInFlow: "popup",
	signInOptions: [
		firebase.auth.GoogleAuthProvider.PROVIDER_ID,
		{
			provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
			scopes: ["public_profile", "email"],
		},
		/* firebase.auth.TwitterAuthProvider.PROVIDER_ID,
		firebase.auth.GithubAuthProvider.PROVIDER_ID, */
		{
			requireDisplayName: true,
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
		},
	],
	tosUrl: "https://kdpextension.com/tos",
	privacyPolicyUrl: "https://kdpextension.com/pp",
	credentialHelper: firebaseui.auth.CredentialHelper.GOOGLE_YOLO,
}
