<div class="coupon-container">
	<div class="card">
		<div class="info-container">
			<div class="logo">
				<img src="../../../assets/images/icon.png" alt="" />
			</div>
			<div class="vertical"></div>
			<div class="info">
				<div class="title">Lifetime subscription</div>
				<div class="discount">75% off</div>
				<div class="">Limited to 100 redemptions</div>
			</div>
		</div>
		<div class="copy-button">
			<input id="copyvalue" type="text" readonly value="EARLY75OFF" />
			<button
				gaEvent="copy_coupon_click"
				gaCategory="coupon_component"
				(click)="copyCode('EARLY75OFF')"
				class="copybtn"
			>
				{{ buttonText }}
			</button>
		</div>
		<div class="circle1"></div>
		<div class="circle2"></div>
	</div>
</div>
