import { Component } from "@angular/core"
import { NavigationEnd, Router } from "@angular/router"
import { GoogleTagManagerService } from "angular-google-tag-manager"
import { GoogleAnalyticsService } from "ngx-google-analytics"
import { AuthService } from "./shared/services/auth.service"

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent {
	public isLoggedIn: boolean = false
	constructor(
		public authService: AuthService,
		protected $gaService: GoogleAnalyticsService,
		private gtmService: GoogleTagManagerService,
		public router: Router
	) {
		this.authService.user$.subscribe((user) => {
			this.isLoggedIn = user !== null ? true : false
		})
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				this.$gaService.pageView(event.urlAfterRedirects, event.urlAfterRedirects)
			}
		})
	}
}
