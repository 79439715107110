import { HttpClient, HttpParams } from "@angular/common/http"
import { Injectable, NgZone } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/auth"
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore"
import { Router } from "@angular/router"
import { BehaviorSubject } from "rxjs"
import { User } from "./user"

@Injectable({
	providedIn: "root",
})
export class AuthService {
	user$ = new BehaviorSubject<User>(null)
	myUrl: "https://us-central1-kdpextension.cloudfunctions.net/syncExtension?uid="

	constructor(
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		public router: Router,
		public ngZone: NgZone,
		private window: Window,
		private httpClient: HttpClient
	) {
		this.afAuth.authState.subscribe((user) => {
			if (user) {
				this.user$.next(user)
				localStorage.setItem("user", JSON.stringify(user))
				try {
					this.httpClient
						.get("https://us-central1-kdpextension.cloudfunctions.net/syncExtension?uid=" + user.uid)
						.subscribe((response: any) => {
							var data = {
								type: "SYNC_AUTH_STATE",
								token: response.result,
							}
							this.window.postMessage(data, "*")
						})
				} catch (e) {}
				this.SetUserData(user)
			} else {
				localStorage.setItem("user", null)
			}
		})
	}
	get isLoggedIn(): boolean {
		console.log("isLoggedIn")
		const user = JSON.parse(localStorage.getItem("user"))
		return user !== null ? true : false
	}
	SetUserData(user) {
		const userRef: AngularFirestoreDocument<any> = this.afs.doc(`customers/${user.uid}`)
		const userData: User = {
			uid: user.uid,
			email: user.email,
			displayName: user.displayName,
			photoURL: user.photoURL,
			emailVerified: user.emailVerified,
		}
		return userRef.set(userData, {
			merge: true,
		})
	}
	SignOut() {
		return this.afAuth.signOut().then(() => {
			this.user$.next(null)
			localStorage.removeItem("user")
			this.router.navigate(["signin"])
		})
	}
}
