import { NgModule } from "@angular/core"
import { Routes, RouterModule } from "@angular/router"
import { HomeComponent } from "./pages/home/home.component"
import { PaymentSuccessComponent } from "./pages/payment-success/payment-success.component"
import { ProfileComponent } from "./pages/profile/profile.component"
import { SignInComponent } from "./pages/sign-in/sign-in.component"
import { AuthGuard } from "./shared/auth.guard"

const routes: Routes = [
	{ path: "", component: HomeComponent },
	{ path: "signin", component: SignInComponent },
	{ path: "profile", component: ProfileComponent, canActivate: [AuthGuard] },
	{ path: "payment-success", component: PaymentSuccessComponent, canActivate: [AuthGuard] },
]

@NgModule({
	imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
	exports: [RouterModule],
})
export class AppRoutingModule {}
