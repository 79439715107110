<div class="flex-center">
	<div class="img-container">
		<img class="mat-elevation-z4" src="./../../../assets/images/mabcb-cover.png" alt="" />
	</div>
	<div class="amz-bsr">
		<div *ngIf="options.bsr" class="header">BSR</div>
		<div *ngIf="options.bsr" class="center {{ bsr | bsrColor }}">
			<b>#{{ bsr | number: "1.0" }}</b>
		</div>
		<div *ngIf="options.pages" class="header">Pages</div>
		<div *ngIf="options.pages" class="center">{{ pages }}</div>
		<div *ngIf="options.royalties" class="header">Royalties</div>
		<div *ngIf="options.royalties" class="center">
			${{ pages | royaltiesCalculator: price }} per book
		</div>
		<div *ngIf="options.dailySales" class="header">
			Sales
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 69.43">
				<defs>
					<style>
						.cls-1 {
							fill: #777777;
						}
					</style>
				</defs>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Capa_1" data-name="Capa 1">
						<path
							class="cls-1"
							d="M45.27,5.43V0H42.85V5.43H21.14V0H18.73V5.43H0v64H64v-64ZM2.41,7.84H18.73v4.83h2.41V7.84H42.85v4.83h2.42V7.84H61.58V21.41H2.41ZM61.58,67H2.41V23.82H61.59V67Z"
						></path>
						<path class="cls-1" d="M32.94,35.7h-.08l-4.52,2.44-.68-2.68,5.68-3h3v26h-3.4Z"></path>
					</g>
				</g>
			</svg>
		</div>
		<div *ngIf="options.dailySales" class="center">{{ bsr | salesCalculator }} pcs</div>
		<div *ngIf="options.monthlySales" class="header">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 69.43">
				<defs>
					<style>
						.cls-1 {
							fill: #777777;
						}
					</style>
				</defs>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Capa_1" data-name="Capa 1">
						<path
							class="cls-1"
							d="M45.27,5.43V0H42.85V5.43H21.14V0H18.73V5.43H0v64H64v-64ZM2.41,7.84H18.73v4.83h2.41V7.84H42.85v4.83h2.42V7.84H61.58V21.41H2.41ZM61.58,67H2.41V23.82H61.59V67Z"
						></path>
						<path
							class="cls-1"
							d="M17.12,54.38A11.77,11.77,0,0,0,22.88,56c4.52,0,5.92-2.88,5.88-5,0-3.64-3.32-5.2-6.72-5.2h-2V43.14h2c2.56,0,5.8-1.32,5.8-4.4,0-2.08-1.32-3.92-4.56-3.92a9.55,9.55,0,0,0-5.2,1.72L17.16,34A12.36,12.36,0,0,1,24,32c5.12,0,7.44,3,7.44,6.2,0,2.68-1.6,5-4.8,6.12v.08a6.77,6.77,0,0,1,5.8,6.68c0,4.16-3.24,7.8-9.48,7.8a13.22,13.22,0,0,1-6.76-1.76Z"
						></path>
						<path class="cls-1" d="M44.44,35.7h-.08l-4.52,2.44-.68-2.68,5.68-3h3v26h-3.4Z"></path>
					</g>
				</g>
			</svg>
		</div>
		<div *ngIf="options.monthlySales" class="center">{{ (bsr | salesCalculator) * 30 }} pcs</div>
		<div *ngIf="options.dailyProfit" class="header">
			Profit<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 69.43">
				<defs>
					<style>
						.cls-1 {
							fill: #777777;
						}
					</style>
				</defs>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Capa_1" data-name="Capa 1">
						<path
							class="cls-1"
							d="M45.27,5.43V0H42.85V5.43H21.14V0H18.73V5.43H0v64H64v-64ZM2.41,7.84H18.73v4.83h2.41V7.84H42.85v4.83h2.42V7.84H61.58V21.41H2.41ZM61.58,67H2.41V23.82H61.59V67Z"
						></path>
						<path class="cls-1" d="M32.94,35.7h-.08l-4.52,2.44-.68-2.68,5.68-3h3v26h-3.4Z"></path>
					</g>
				</g>
			</svg>
		</div>
		<div *ngIf="options.dailyProfit" class="center">
			{{ (bsr | salesCalculator) * (pages | royaltiesCalculator: price) | number: "1.2" }} USD
		</div>
		<div *ngIf="options.monthlyProfit" class="header">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 69.43">
				<defs>
					<style>
						.cls-1 {
							fill: #777777;
						}
					</style>
				</defs>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Capa_1" data-name="Capa 1">
						<path
							class="cls-1"
							d="M45.27,5.43V0H42.85V5.43H21.14V0H18.73V5.43H0v64H64v-64ZM2.41,7.84H18.73v4.83h2.41V7.84H42.85v4.83h2.42V7.84H61.58V21.41H2.41ZM61.58,67H2.41V23.82H61.59V67Z"
						></path>
						<path
							class="cls-1"
							d="M17.12,54.38A11.77,11.77,0,0,0,22.88,56c4.52,0,5.92-2.88,5.88-5,0-3.64-3.32-5.2-6.72-5.2h-2V43.14h2c2.56,0,5.8-1.32,5.8-4.4,0-2.08-1.32-3.92-4.56-3.92a9.55,9.55,0,0,0-5.2,1.72L17.16,34A12.36,12.36,0,0,1,24,32c5.12,0,7.44,3,7.44,6.2,0,2.68-1.6,5-4.8,6.12v.08a6.77,6.77,0,0,1,5.8,6.68c0,4.16-3.24,7.8-9.48,7.8a13.22,13.22,0,0,1-6.76-1.76Z"
						></path>
						<path class="cls-1" d="M44.44,35.7h-.08l-4.52,2.44-.68-2.68,5.68-3h3v26h-3.4Z"></path>
					</g>
				</g>
			</svg>
		</div>
		<div *ngIf="options.monthlyProfit" class="center">
			{{ (bsr | salesCalculator) * (pages | royaltiesCalculator: price) * 30 | number: "1.2" }} USD
		</div>
	</div>
</div>
