import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "royaltiesCalculator",
})
export class RoyaltiesCalculatorPipe implements PipeTransform {
	transform(pages: number, price: number): number {
		const printCost = pages * this.costPerPage(pages) + this.fixedCostCalculator(pages)
		return Math.floor((price * 0.6 - printCost) * 100) / 100
	}
	fixedCostCalculator(pages) {
		var cost
		if (pages < 110) {
			cost = 2.15
		} else {
			cost = 0.85
		}
		return cost
	}
	costPerPage(pages) {
		var cost
		if (pages < 110) {
			cost = 0
		} else {
			cost = 0.012
		}
		return cost
	}
}
