import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "bsrColor",
})
export class BsrColorPipe implements PipeTransform {
	transform(bsr: number): string {
		var cssClass
		if (bsr == 0) {
			cssClass = "r0"
		} else if (bsr < 100) {
			cssClass = "r1"
		} else if (bsr < 1000) {
			cssClass = "r2"
		} else if (bsr < 3000) {
			cssClass = "r3"
		} else if (bsr < 20000) {
			cssClass = "r4"
		} else if (bsr < 112501) {
			cssClass = "r5"
		} else {
			cssClass = "r6"
		}
		return cssClass
	}
}
