<div class="row">
	<div class="col s12">
		<h1>Hi there!</h1>
		<firebase-ui
			(signInSuccessWithAuthResult)="successCallback($event)"
			(signInFailure)="errorCallback($event)"
		></firebase-ui>
		<div *ngIf="loading" style="text-align: center">
			<app-loading></app-loading>
			<p>Loading...</p>
		</div>
	</div>
</div>
