<mat-card class="mat-elevation-z2">
	<mat-card-title>
		<div class="license-container">
			<button class="license" [class.active]="interval === 'month'" (click)="changePeriod('month')">
				Monthy
			</button>
			<button class="license" [class.active]="interval === 'year'" (click)="changePeriod('year')">
				Yearly
			</button>
			<button
				class="license"
				[class.active]="interval === 'lifetime'"
				(click)="changePeriod('lifetime')"
			>
				Lifetime
			</button>
		</div>
	</mat-card-title>
	<mat-card-content>
		<div class="price-container">
			<div *ngIf="!useCoupon || (useCoupon && interval != 'lifetime')" class="pricing">
				<sup>$</sup><span>{{ pricing[interval].price }}</span>
			</div>
			<div *ngIf="useCoupon && interval == 'lifetime'" class="pricing">
				<sup>$</sup><span>49.99</span>
			</div>
			<div class="coupon-container"></div>
			<ul>
				<li>1 User</li>
				<li *ngIf="interval != 'lifetime'" style="font-weight: bolder">
					Try full version for 15 days
				</li>
				<li *ngIf="interval == 'lifetime'" style="font-weight: bolder">One time investment</li>
				<li>Best Seller Rank</li>
				<li>Number of pages</li>
				<li>Royalties per book</li>
				<li>Daily and monthly sales</li>
				<li>Daily and monthly profit</li>
				<li>Customizable options</li>
			</ul>
			<div *ngIf="interval == 'lifetime'" class="coupon-checkbox-container">
				<mat-checkbox [(ngModel)]="useCoupon" color="primary">
					<span class="coupon-text" [class.applied]="useCoupon">
						Save <b>75%</b> when you <br />
						apply this coupon.</span
					>
				</mat-checkbox>
			</div>
			<button
				gaEvent="start_now_button_click"
				gaCategory="pricing_card"
				(click)="buttonClick($event)"
				class="button"
			>
				Start Now
			</button>
			<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
		</div>
	</mat-card-content>
</mat-card>
