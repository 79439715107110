<header>
	<div class="avatar">
		<img src="{{ user?.photoURL || '../../../../../assets/images/icon.png' }}" alt="" />
	</div>
	<h1>{{ user?.displayName }}</h1>
	<p>{{ user?.email }}</p>
	<p><button mat-flat-button (click)="authService.SignOut()">Sign out</button></p>
</header>
<div *ngIf="haveSubscription" class="container">
	<div class="row">
		<div class="col s12">
			<div class="flex-center">
				<h2 class="hero">Subscription</h2>
				<p class="lead">${{ price?.unit_amount / 100 }} USD / {{ price?.interval }}</p>
				<p *ngIf="subscription?.status == 'trialing'">
					Trial period ends on {{ subscription.trial_end.seconds * 1000 | date: "fullDate" }}
				</p>
				<p *ngIf="subscription?.type != 'lifetime'">
					<button mat-stroked-button color="primary" (click)="sendToCustomerPortal()">
						Manage your subscription <mat-icon svgIcon="arrow-right-thick"></mat-icon>
					</button>
				</p>
				<p *ngIf="subscription?.type === 'lifetime'">Lifetime Subscription</p>
			</div>
		</div>
	</div>
</div>
<div *ngIf="!haveSubscription" class="price-container">
	<div class="container">
		<div class="row">
			<div class="col s12"></div>
			<div class="row">
				<div class="col s12">
					<div class="flex-center">
						<app-coupon></app-coupon>
						<h2 class="hero">Simple Pricing</h2>
						<p class="lead">Easy pricing means no surprises.</p>
						<app-pricing-card></app-pricing-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="v-spacer"></div>
