import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { Router } from "@angular/router"
import { AuthService } from "src/app/shared/services/auth.service"
import { pricing } from "src/app/shared/globals"

@Component({
	selector: "app-pricing-card",
	templateUrl: "./pricing-card.component.html",
	styleUrls: ["./pricing-card.component.scss"],
})
export class PricingCardComponent implements OnInit {
	@Output() onButtonClick: EventEmitter<any> = new EventEmitter()
	loading: boolean = false
	interval = "lifetime"
	pricing
	useCoupon = false
	promo = "promo_1LGX5FDOIP1yYaDr7VsWykU0"
	constructor(public authService: AuthService, public afs: AngularFirestore, public router: Router) {
		this.pricing = pricing
	}

	ngOnInit(): void {}
	buttonClick(event) {
		event.srcElement.disabled = this.loading = true
		event.srcElement.innerText = "Loading..."
		this.sendToCheckout()
	}
	changePeriod(interval) {
		this.interval = interval
	}

	sendToCheckout() {
		this.authService.user$.subscribe((user) => {
			if (user != null) {
				if (this.interval === "lifetime") {
					const payment = {
						mode: "payment",
						price: this.pricing[this.interval].priceId,
						allow_promotion_codes: true,
						success_url: window.location.origin,
						cancel_url: window.location.origin,
						promotion_code: null,
					}
					if (this.useCoupon) {
						payment.promotion_code = this.promo
					}
					console.log(payment)

					this.afs
						.doc(`customers/${user.uid}`)
						.collection("checkout_sessions")
						.add(payment)
						.then((docRef) => {
							docRef.onSnapshot(async (snap) => {
								const { error, url } = snap.data()
								if (error) {
									alert(`An error occurred: ${error.message}`)
								}
								if (url) {
									// We have a Stripe Checkout URL, let's redirect.
									window.location.assign(url)
								}
							})
						})
				} else {
					this.afs
						.doc(`customers/${user.uid}`)
						.collection("checkout_sessions")
						.add({
							price: this.pricing[this.interval].priceId,
							allow_promotion_codes: true,
							success_url: window.location.origin,
							cancel_url: window.location.origin,
						})
						.then((docRef) => {
							docRef.onSnapshot(async (snap) => {
								const { error, url } = snap.data()
								if (error) {
									alert(`An error occurred: ${error.message}`)
								}
								if (url) {
									// We have a Stripe Checkout URL, let's redirect.
									window.location.assign(url)
								}
							})
						})
				}
			} else {
				const queryParams = { interval: this.interval, promo: null }
				if (this.useCoupon) {
					queryParams.promo = this.promo
				}

				this.router.navigate(["signin"], { queryParams: queryParams })
			}
		})
	}

	animateValue(id, start, end, duration) {
		if (start === end) return
		var range = end - start
		var current = start
		var increment = end > start ? 1 : -1
		var stepTime = Math.abs(Math.floor(duration / range))
		var obj = document.getElementById(id)
		var timer = setInterval(function () {
			current += increment
			this.pricing.lifetime.price = current
			//obj.innerHTML = current;
			if (current == end) {
				clearInterval(timer)
			}
		}, stepTime)
	}
}
