import { Component, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { FormControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from "firebaseui-angular"
import { AuthService } from "src/app/shared/services/auth.service"

import { pricing } from "src/app/shared/globals"

@Component({
	selector: "app-sign-in",
	templateUrl: "./sign-in.component.html",
	styleUrls: ["./sign-in.component.scss"],
})
export class SignInComponent implements OnInit {
	email = new FormControl()
	password = new FormControl()
	interval: any
	pricing
	loading: boolean = false
	promo

	constructor(
		public authService: AuthService,
		public afs: AngularFirestore,
		private router: Router,
		private activatedRoute: ActivatedRoute
	) {
		this.pricing = pricing
		this.interval = this.activatedRoute.snapshot.queryParamMap.get("interval")
		;(this.promo = this), activatedRoute.snapshot.queryParamMap.get("promo")
	}

	ngOnInit(): void {}
	onLogin(): void {}
	successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {
		localStorage.setItem("user", JSON.stringify(signInSuccessData.authResult.user))
		this.loading = true
		if (this.interval) {
			this.authService.user$.subscribe((user) => {
				if (user != null) {
					if (this.interval === "lifetime") {
						const payment = {
							mode: "payment",
							price: this.pricing[this.interval].priceId,
							allow_promotion_codes: true,
							success_url: window.location.origin,
							cancel_url: window.location.origin,
							promotion_code: null,
						}
						if (this.promo) {
							payment["promotion_code"] = this.promo
						}
						console.log(payment)

						this.afs
							.doc(`customers/${user.uid}`)
							.collection("checkout_sessions")
							.add(payment)
							.then((docRef) => {
								docRef.onSnapshot(async (snap) => {
									const { error, url } = snap.data()
									if (error) {
										alert(`An error occurred: ${error.message}`)
									}
									if (url) {
										// We have a Stripe Checkout URL, let's redirect.
										window.location.assign(url)
									}
								})
							})
					} else {
						this.afs
							.doc(`customers/${user.uid}`)
							.collection("checkout_sessions")
							.add({
								price: this.pricing[this.interval].priceId,
								success_url: window.location.origin,
								cancel_url: window.location.origin,
							})
							.then((docRef) => {
								docRef.onSnapshot(async (snap) => {
									const { error, url } = snap.data()
									if (error) {
										alert(`An error occurred: ${error.message}`)
									}
									if (url) {
										// We have a Stripe Checkout URL, let's redirect.
										window.location.assign(url)
									}
								})
							})
					}
				}
			})
		} else {
			this.router.navigate(["profile"])
		}
	}

	errorCallback(errorData: FirebaseUISignInFailure) {
		console.log(errorData)
	}
}
