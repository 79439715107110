import { BrowserModule, DomSanitizer } from "@angular/platform-browser"
import { HttpClientModule } from "@angular/common/http"
import { NgModule } from "@angular/core"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { AngularFireModule } from "@angular/fire"
import { AngularFireAuthModule } from "@angular/fire/auth"
import { AngularFirestoreModule } from "@angular/fire/firestore"
import { AngularFireFunctionsModule } from "@angular/fire/functions"
import { environment } from "../environments/environment"

import { AuthService } from "./shared/services/auth.service"
import { HomeComponent } from "./pages/home/home.component"
import { FooterComponent } from "./components/footer/footer.component"
import { ProfileComponent } from "./pages/profile/profile.component"
import { SignInComponent } from "./pages/sign-in/sign-in.component"

import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatChipsModule } from "@angular/material/chips"
import { MatIconModule, MatIconRegistry } from "@angular/material/icon"
import { MatProgressBarModule } from "@angular/material/progress-bar"
import { MatSnackBarModule } from "@angular/material/snack-bar"
import { MatSlideToggleModule } from "@angular/material/slide-toggle"
import { MatToolbarModule } from "@angular/material/toolbar"
import { PricingCardComponent } from "./components/pricing-card/pricing-card.component"
import { KdpextPopupComponent } from "./components/kdpext-popup/kdpext-popup.component"
import { KdpextContentComponent } from "./components/kdpext-content/kdpext-content.component"
import { BsrColorPipe } from "./shared/bsr-color.pipe"
import { RoyaltiesCalculatorPipe } from "./shared/royalties-calculator.pipe"
import { SalesCalculatorPipe } from "./shared/sales-calculator.pipe"
import { firebaseUiAuthConfig } from "./shared/firebaseui-config"
import { FirebaseUIModule } from "firebaseui-angular"
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from "ngx-google-analytics"
import { PaymentSuccessComponent } from "./pages/payment-success/payment-success.component"
import { GoogleTagManagerModule } from "angular-google-tag-manager"
import { LoadingComponent } from "./components/loading/loading.component"
import { CouponComponent } from "./components/coupon/coupon.component"

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		FooterComponent,
		SignInComponent,
		ProfileComponent,
		PricingCardComponent,
		KdpextPopupComponent,
		KdpextContentComponent,
		BsrColorPipe,
		RoyaltiesCalculatorPipe,
		SalesCalculatorPipe,
		PaymentSuccessComponent,
		LoadingComponent,
		CouponComponent,
	],
	imports: [
		FormsModule,
		BrowserModule,
		ReactiveFormsModule,
		AngularFireModule.initializeApp(environment.firebase),
		FirebaseUIModule.forRoot(firebaseUiAuthConfig),
		NgxGoogleAnalyticsModule.forRoot("G-EB3KGS60SH"),
		GoogleTagManagerModule.forRoot({
			id: "GTM-T4T7WJK",
		}),
		NgxGoogleAnalyticsRouterModule,
		AngularFireAuthModule,
		AngularFirestoreModule,
		AngularFireFunctionsModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatChipsModule,
		MatIconModule,
		MatProgressBarModule,
		MatSnackBarModule,
		MatSlideToggleModule,
		MatToolbarModule,
	],
	providers: [AuthService, { provide: Window, useValue: window }],
	bootstrap: [AppComponent],
})
export class AppModule {
	constructor(iconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
		iconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl("./assets/mdi.svg"))
	}
}
