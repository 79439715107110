export const pricing = {
	month: {
		price: 2.99,
		text: "/mo",
		priceId: "price_1IBOkZDOIP1yYaDrVRCj00d1",
	},
	year: {
		price: 29.95,
		text: "/yr",
		priceId: "price_1IBOkZDOIP1yYaDrDrrL4puu",
	},
	lifetime: {
		price: 199.95,
		text: "/lifetime",
		priceId: "price_1LGWa2DOIP1yYaDrhKjuDSdw",
	},
}
