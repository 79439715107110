<footer>
	<div class="container">
		<div class="row">
			<div class="col s12 m6 l6">
				<img height="24" src="./../../../assets/images/logo-full-caps-horizontal.png" alt="" />
				&copy;
			</div>
			<div class="col s12 l3">
				<!-- <a mat-icon-button href="https://facebook.com/kdpextension" target="blank">
					<mat-icon svgIcon="facebook"></mat-icon>
				</a>
				<a
					mat-icon-button
					aria-label="Síguenos en Instagram"
					href="https://instagram.com/rutadirecta"
					target="blank"
				>
					<mat-icon svgIcon="instagram"></mat-icon>
				</a>
				<a
					mat-icon-button
					aria-label="Síguenos en Youtube"
					href="https://youtube.com/rutadirecta"
					target="blank"
				>
					<mat-icon svgIcon="youtube"></mat-icon>
				</a>
				<a
					mat-icon-button
					aria-label="Síguenos en Twitter"
					href="https://twitter.com/rutadirecta"
					target="blank"
				>
					<mat-icon svgIcon="twitter"></mat-icon>
				</a> -->
			</div>

			<div class="col s12 l3">KDP Extension &copy; 2021</div>
		</div>
	</div>
</footer>
