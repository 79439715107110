import { Component, EventEmitter, OnInit, Output } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { GoogleAnalyticsService } from "ngx-google-analytics"

@Component({
	selector: "app-kdpext-popup",
	templateUrl: "./kdpext-popup.component.html",
	styleUrls: ["./kdpext-popup.component.scss"],
})
export class KdpextPopupComponent implements OnInit {
	@Output() onOptionsChange: EventEmitter<any> = new EventEmitter()
	form = new FormGroup({
		bsr: new FormControl(true),
		pages: new FormControl(true),
		royalties: new FormControl(true),
		dailySales: new FormControl(true),
		monthlySales: new FormControl(true),
		dailyProfit: new FormControl(true),
		monthlyProfit: new FormControl(true),
	})
	constructor(protected $gaService: GoogleAnalyticsService) {}

	ngOnInit(): void {
		this.form.valueChanges.subscribe((values) => {
			this.$gaService.event("popup_settings_change", "popup_component")
			this.onOptionsChange.emit(values)
		})
	}
}
