<header>
	<h1 class="hero">The Amazon Research Tool<br />for KDP Publishers</h1>
	<div class="container">
		<div class="row">
			<div class="col s12 m8 offset-m2">
				<div class="iframe-container">
					<iframe
						width="560"
						height="315"
						src="https://www.youtube.com/embed/jOUee9XUOyc"
						title="YouTube video player"
						frameborder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowfullscreen
					></iframe>
				</div>
			</div>
		</div>
	</div>
</header>
<div class="container">
	<div class="row">
		<div class="col s12">
			<h1 class="hero">How Does It Looks?</h1>
		</div>
		<div class="col s12 l5 offset-l1 flex-center playground">
			<h3 class="hero">Extension Popup</h3>
			<app-kdpext-popup (onOptionsChange)="updateOptions($event)"></app-kdpext-popup>
			<h3 class="hero">Playground</h3>
			<p style="text-align: center">
				With the "BSR Colors" you can spot the best seller books quickly in a search results on
				Amazon. Try BSR's of 10, 100, 1,000, 10,000 and 100,000 and 1,000,000 or any other number and
				watch ;)
			</p>
			<div class="row">
				<div class="col s4">
					<label for="">BSR</label>
					<input type="text" [formControl]="bsr" (keyup)="onKeyUp($event, 'bsr')" />
				</div>
				<div class="col s4">
					<label for="">Pages</label>
					<input type="text" [formControl]="pages" (keyup)="onKeyUp($event, 'pages')" />
				</div>
				<div class="col s4">
					<label for="">Price</label>
					<input type="text" [formControl]="price" (keyup)="onKeyUp($event, 'price')" />
				</div>
			</div>
		</div>

		<div class="col s12 l6 flex-center">
			<h3 class="hero">Amazon Search Result</h3>
			<app-kdpext-content
				[bsr]="bsr.value"
				[pages]="pages.value"
				[price]="price.value"
				[options]="options"
			></app-kdpext-content>
		</div>
	</div>
	<div class="row">
		<div class="col s12 l8 offset-l2">
			<h2>Manifesto</h2>
			<p>
				I'm an experienced software developer and designer that, like you, are trying to build
				another stream of income with Amazon Kindle Direct Publishing (Amazon KDP) but it turned a
				real <span class="censored">pain in the...</span> time investment to research which low
				content books are selling well on Amazon because I'm sure everyone of us have made the next
				steps:
			</p>
			<ol>
				<li>Search for a niche in Amazon like "Coloring books for adults"</li>
				<li>Click on one book</li>
				<li>Search for the Best Seller Rank (BSR), double click on it and <b>COPY</b></li>
				<li>Open Google an search "BSR to sales calculator"</li>
				<li><b>PASTE</b> the BSR to get de daily and monthly sales</li>
				<li>Copy info to excel and repeat...</li>
			</ol>
			<p>I know is easy, but super tedious.</p>
			<p>
				KDP Extension do all of this for you. You only search for a niche in Amazon and the extension
				will inject below every (doable) book this information:
			</p>
			<ol>
				<li>Color coded BSR</li>
				<li>Number of pages</li>
				<li>Royalties per book</li>
				<li>Estimated daily and monthly sales</li>
				<li>Estimated daily and monthly profit</li>
			</ol>
			<p>
				You only need to Install the extension to see how it works in an Amazon search results and
				start saving a <b>LOT</b> of your time
			</p>
			<p><b>Happy selling!</b></p>
		</div>
	</div>
	<div class="v-spacer"></div>
	<app-coupon></app-coupon>
	<div class="v-spacer"></div>
	<div class="v-spacer"></div>
	<div class="v-spacer"></div>
	<div class="v-spacer"></div>
	<div class="v-spacer"></div>
</div>

<div class="price-container">
	<div class="container">
		<div class="row">
			<div class="col s12"></div>
			<div class="row">
				<div class="col s12">
					<div class="flex-center">
						<h2 class="hero">Simple Pricing</h2>
						<p class="lead">Easy pricing means no surprises.</p>
						<app-pricing-card></app-pricing-card>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="v-spacer"></div>
	<div class="v-spacer"></div>
	<div class="v-spacer"></div>
</div>
