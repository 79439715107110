<mat-toolbar>
	<mat-toolbar-row>
		<div class="container">
			<span>
				<a routerLink="/">
					<img height="16" src="./../../../assets/images/logo-full-caps-horizontal.png" alt="" />
				</a>
			</span>
			<span class="flex-spacer"></span>
			<a
				gaEvent="chrome_extension_link_click"
				gaCategory="navbar"
				mat-button
				href="https://chrome.google.com/webstore/detail/kdp-extension/lcknenpjnkghhjmjhlhmmbmnpolememl"
				target="_blank"
				>Chrome Extension</a
			>
			<a
				gaEvent="login_link_click"
				gaCategory="navbar"
				mat-button
				routerLink="/signin"
				*ngIf="!isLoggedIn; else signoutButton"
			>
				Login
			</a>
			<ng-template #signoutButton>
				<a gaEvent="profile_link_click" gaCategory="navbar" mat-button routerLink="/profile">
					Profile
				</a>
			</ng-template>
		</div>
	</mat-toolbar-row>
</mat-toolbar>
<router-outlet></router-outlet>

<app-footer></app-footer>
