<div class="popup">
	<div class="body mat-elevation-z2">
		<div class="popup-container">
			<form [formGroup]="form">
				<div class="header">
					<img src="./../../../assets/images/logo-horizontal.png" alt="" />
				</div>
				<div class="neumorphism-toggle">
					<input formControlName="bsr" type="checkbox" name="settings" value="bsr" id="bsr" />
					<label for="bsr">
						<span>BSR</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
				<div class="neumorphism-toggle">
					<input formControlName="pages" type="checkbox" name="settings" value="pages" id="pages" />
					<label for="pages">
						<span>Pages</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
				<div class="neumorphism-toggle">
					<input
						formControlName="royalties"
						type="checkbox"
						name="settings"
						value="royalties"
						id="royalties"
					/>
					<label for="royalties">
						<span>Royalties</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
				<div class="neumorphism-toggle">
					<input
						formControlName="dailySales"
						type="checkbox"
						name="settings"
						value="dailySales"
						id="dailySales"
					/>
					<label for="dailySales">
						<span>Daily Sales</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
				<div class="neumorphism-toggle">
					<input
						formControlName="monthlySales"
						type="checkbox"
						name="settings"
						value="monthlySales"
						id="monthlySales"
					/>
					<label for="monthlySales">
						<span>Monthly Sales</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
				<div class="neumorphism-toggle">
					<input
						formControlName="dailyProfit"
						type="checkbox"
						name="settings"
						value="dailyProfit"
						id="dailyProfit"
					/>
					<label for="dailyProfit">
						<span>Daily profit</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
				<div class="neumorphism-toggle">
					<input
						formControlName="monthlyProfit"
						type="checkbox"
						name="settings"
						value="monthlyProfit"
						id="monthlyProfit"
					/>
					<label for="monthlyProfit">
						<span>Monthly profit</span>
						<div class="switch">
							<div class="dot"></div>
						</div>
					</label>
				</div>
			</form>
		</div>
	</div>
</div>
