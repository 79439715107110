import { Component, OnInit } from "@angular/core"
import { AngularFirestore, AngularFirestoreDocument } from "@angular/fire/firestore"
import { AngularFireFunctions } from "@angular/fire/functions"
import { loadStripe } from "@stripe/stripe-js"
import { AuthService } from "src/app/shared/services/auth.service"
import { User } from "src/app/shared/services/user"

@Component({
	selector: "app-profile",
	templateUrl: "./profile.component.html",
	styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
	user: User
	haveSubscription: boolean
	subscription: any
	price: any
	constructor(
		public authService: AuthService,
		public aff: AngularFireFunctions,
		public afs: AngularFirestore
	) {}

	ngOnInit(): void {
		this.user = JSON.parse(localStorage.getItem("user"))
		this.authService.user$.subscribe((user) => {
			if (user) {
				this.user = user
				this.afs
					.collection("customers")
					.doc(this.user.uid)
					.collection("subscriptions", (ref) => ref.where("status", "in", ["trialing", "active"]))
					.valueChanges({ idField: "id" })
					.subscribe((data) => {
						this.haveSubscription = data.length > 0 ? true : false
						this.subscription = data[0]
						this.subscription.price.get().then((result) => {
							this.price = result.data()
							console.log(this.price)
						})
					})
			}
		})
	}

	async sendToCustomerPortal() {
		if (this.haveSubscription) {
			const functionRef = this.aff.httpsCallable(
				"ext-firestore-stripe-subscriptions-createPortalLink"
			)
			const data: any = await functionRef({
				returnUrl: "https://kdpextension.com/profile",
			}).subscribe((data) => {
				window.location.href = data.url
			})
		}
	}
}
