import { Component, OnInit } from "@angular/core"
import { Clipboard } from "@angular/cdk/clipboard"
import { GoogleAnalyticsService } from "ngx-google-analytics"

@Component({
	selector: "app-coupon",
	templateUrl: "./coupon.component.html",
	styleUrls: ["./coupon.component.scss"],
})
export class CouponComponent implements OnInit {
	buttonText = "Copy"
	constructor(protected $gaService: GoogleAnalyticsService, private clipboard: Clipboard) {}

	ngOnInit(): void {}

	copyCode(textToCopy) {
		this.clipboard.copy(textToCopy)
		this.buttonText = "Copied"
		setTimeout(() => {
			this.buttonText = "Copy"
		}, 1000)
	}
}
