import { Component, OnInit } from "@angular/core"
import { AngularFirestore } from "@angular/fire/firestore"
import { FormControl } from "@angular/forms"
import { Router } from "@angular/router"
import { loadStripe } from "@stripe/stripe-js"
import { AuthService } from "src/app/shared/services/auth.service"

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	bsr = new FormControl(106)
	pages = new FormControl(109)
	price = new FormControl(6.99)
	options = {
		bsr: true,
		pages: true,
		royalties: true,
		dailySales: true,
		monthlySales: true,
		dailyProfit: true,
		monthlyProfit: true,
	}

	constructor(
		public authService: AuthService,
		public afs: AngularFirestore,
		public router: Router
	) {}

	ngOnInit(): void {}

	updateOptions(options) {
		this.options = options
	}
	onKeyUp(event, option) {
		if (event.key == "ArrowUp") {
			this[option].setValue(this[option].value * 1 + 1)
		}
		if (event.key == "ArrowDown") {
			this[option].setValue(this[option].value - 1)
		}
	}
	/* sendToCheckout(price) {
		this.authService.user$.subscribe((user) => {
			if (user != null) {
				console.log(price)

				this.afs
					.doc(`customers/${user.uid}`)
					.collection("checkout_sessions")
					.add({
						price: price,
						success_url: window.location.origin,
						cancel_url: window.location.origin,
					})
					.then((docRef) => {
						docRef.onSnapshot(async (snap) => {
							const { error, sessionId } = snap.data()
							if (error) {
								alert(`An error occurred: ${error.message}`)
							}

							if (sessionId) {
								const stripe = await loadStripe(
									"pk_live_51IBOcwDOIP1yYaDr8S6iQfn3UP1qIdjwAFt1MqH8aX4gWa3Kgntfaqdw7BiogIdRMilLYnihX4GrFkVhvYqKiB9U00htrGempX"
								)
								console.log(`redirecting`)
								await stripe.redirectToCheckout({ sessionId })
							}
						})
					})
			} else {
				this.router.navigate(["signin"])
			}
		})
	} */
}
