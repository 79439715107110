import { Component, Input, OnInit } from "@angular/core"

@Component({
	selector: "app-kdpext-content",
	templateUrl: "./kdpext-content.component.html",
	styleUrls: ["./kdpext-content.component.scss"],
})
export class KdpextContentComponent implements OnInit {
	@Input() bsr
	@Input() pages
	@Input() price
	@Input() options: any

	constructor() {}

	ngOnInit(): void {}
}
