import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
	name: "salesCalculator",
})
export class SalesCalculatorPipe implements PipeTransform {
	transform(bsr: number): number {
		var books
		if (bsr >= 1 && bsr <= 5) {
			books = ((2475 - 1350) / 5) * (5 - bsr) + 1350
		}
		if (bsr >= 6 && bsr <= 20) {
			books = ((1250 - 720) / (20 - 5)) * (20 - bsr) + 720
		}
		if (bsr >= 21 && bsr <= 35) {
			books = ((700 - 535) / (35 - 20)) * (35 - bsr) + 535
		}
		if (bsr >= 36 && bsr <= 100) {
			books = ((530 - 300) / (100 - 35)) * (100 - bsr) + 300
		}
		if (bsr >= 101 && bsr <= 200) {
			books = ((300 - 200) / (200 - 100)) * (200 - bsr) + 200
		}
		if (bsr >= 201 && bsr <= 350) {
			books = ((200 - 140) / (350 - 200)) * (350 - bsr) + 140
		}
		if (bsr >= 351 && bsr <= 500) {
			books = ((138 - 110) / (500 - 350)) * (500 - bsr) + 110
		}
		if (bsr >= 501 && bsr <= 750) {
			books = ((108 - 84) / (750 - 500)) * (750 - bsr) + 84
		}
		if (bsr >= 751 && bsr <= 1500) {
			books = ((83 - 50) / (1500 - 750)) * (1500 - bsr) + 50
		}
		if (bsr >= 1501 && bsr <= 3000) {
			books = ((82 - 30) / (3000 - 1500)) * (3000 - bsr) + 30
		}
		if (bsr >= 3001 && bsr <= 5500) {
			books = ((30 - 20) / (5500 - 3000)) * (5500 - bsr) + 20
		}
		if (bsr >= 5501 && bsr <= 10000) {
			books = ((20 - 12) / (10000 - 5500)) * (10000 - bsr) + 12
		}
		if (bsr >= 10001 && bsr <= 50000) {
			books = ((12 - 3) / (50000 - 10000)) * (50000 - bsr) + 3
		}
		if (bsr >= 50001 && bsr <= 300000) {
			books = ((3 - 1) / (100000 - 50000)) * (100000 - bsr) + 1
		}
		if (bsr < 1 || bsr > 300000 || books < 0) {
			books = 0
		}
		return books ? Math.round(books) : 0
	}
}
